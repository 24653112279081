<template>
  <div class="blog-search-result">
    <div class="blog-search-result__wrapper">
      <div class="blog-search-result__header"><span>{{'Search results' + (title ? ' for ' : '')}}</span> {{ title }}</div>
      <div class="blog-search-result__sort-and-count">
        <div class="blog-search-result__count" v-if="searchResults.length > 0">{{ searchResults.length }} results</div>
        <div class="blog-search-result__count" v-if="searchResults.length === 0 && title">Unfortunately, your search returned no results</div>
      </div>
      <vue-scroll :ops="ops">
        <div class="blog-search-result__item">
          <BlogItem v-for="elem of searchResults" :key="elem.id" :elem="elem"/>
        </div>
      </vue-scroll>
    </div>
  </div>
</template>

<script>
  import BlogItem from '@/components/Blog/BlogItem/BlogItem';
  import './BlogSearchResult.styl';

  export default {
    name: 'BlogSearchResult',
    props: ['title'],
    data: () => ({
      ops: {
        rail: {
          size: '4px',
        },
        bar: {
          size: '4px',
          background: 'var(--scroll-bar-background)',
        },
      },
    }),
    computed: {
      searchResults () {
        return this.$store.state.blog.searchResults;
      },
    },
    components: {
      BlogItem,
    },
  };
</script>
