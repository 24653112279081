<template>
  <div class="search">
    <div class="search__back" @click="$router.push('/theflyreport')" v-if="dMobile">
        <SvgIcon name="up-arrow" />
        <span>Search</span>
    </div>
    <form class="search__form">
        <input class="search__field" type="text" v-model="searchRequest" placeholder="What are you looking for?"/>
        <span
        class="search__search-button"
        type="button"
        @click="searchByText"
        :class="{'active' : !!searchRequest}">Search</span>
        <button class="search__button" type="button" @click="$router.go(-1)">
          <SvgIcon name="close"/>
        </button>
    </form>
  </div>
</template>

<script>
  import SvgIcon from '@/components/common/SvgIcon/SvgIcon';
  import './Search.styl';

  export default {
    name: 'Search',
    data: () => ({
      searchRequest: '',
      dMobile: undefined,
    }),
    mounted () {
      this.dMobile = document.querySelector('html').classList.contains('d-mobile');
    },
    methods: {
      searchByText () {
        if (this.searchRequest) {
          this.$emit('search', this.searchRequest);
        }
      },
    },
    components: {
      SvgIcon,
    },
  };
</script>
