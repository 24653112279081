<template>
  <div class="box box--blog">
    <div class="box__inner box__inner--blog">
      <div class="blog-search search" v-if="!dMobile">
        <Search @search="search"/>
      </div>
      <div class="blog-interface">
        <BlogSearchResult :title="searchRequest"/>
        <BlogFeed/>
        <Search @search="search" v-if="dMobile" />
      </div>
    </div>
  </div>
</template>

<script>
  import Search from '@/components/Blog/Search/Search';
  import BlogFeed from '@/components/Blog/BlogFeed/BlogFeed';
  import BlogSearchResult from '@/components/Blog/BlogSearchResult/BlogSearchResult';
  import './Blog.styl';

  export default {
    name: 'Article',
    data: () => ({
      searchRequest: '',
      dMobile: undefined,
    }),
    mounted () {
      this.dMobile = document.querySelector('html').classList.contains('d-mobile');
      this.$store.dispatch('getFeed');
      this.$store.dispatch('clearSearchResults');
    },
    methods: {
      search (searchRequest) {
        this.searchRequest = searchRequest;
        this.$store.dispatch('searchArticles', searchRequest);
      },
    },
    components: {
      Search,
      BlogFeed,
      BlogSearchResult,
    },
  };
</script>
